import HTMLParsedElement from "html-parsed-element"

const DEFAULT_TOLERANCE = 1;

class ScrollIndicator extends HTMLParsedElement {
  parsedCallback() {
    this.displayIndicators()

    const scrollElement = this.getScrollElement()

    if (scrollElement) {
      scrollElement.addEventListener("scroll", () => {
        this.displayIndicators()
      })

    }

    // The "loaded" class enables animation, which should not be done until
    // after the initial render so as to avoid a flash
    // - Aaron, Wed Jan 22 2025
    setTimeout(() => this.classList.add("loaded"), 0)

    this.interval = setInterval(
      () => this.displayIndicators(),
      150
    )
  }

  disconnectedCallback() {
    clearInterval(this.interval)
  }

  displayIndicators() {
    this.classList.remove("content-above", "content-below")

    const scrollElement = this.getScrollElement()

    if (!scrollElement) {
      console.log("NONE")
      return
    }

    const scrollTop = scrollElement.scrollTop
    const scrollHeight = scrollElement.scrollHeight
    const clientHeight = scrollElement.clientHeight
    const tolerance = this.tolerance

    const contentAbove = scrollTop > tolerance
    if (contentAbove) {
      this.classList.add("content-above")
    }

    const contentBelow = Math.abs(scrollHeight - clientHeight - scrollTop) >= tolerance
    if (contentBelow) {
      this.classList.add("content-below")
    }
  }

  getScrollElement() {
    return this.children[0]
  }

  get tolerance() {
    let tolerance = this.getAttribute("tolerance")

    if (tolerance == null) {
      return DEFAULT_TOLERANCE
    }

    return parseInt(tolerance, 10)
  }
}

// This happens synchronously after turbo does its rendering, which is necessary
// to prevent a flash of the initial rendering. The web component uses
// requestAnimationFrame to invoke the parsedCallback, which sometimes causes a
// delay in determining the scroll position. - Aaron, Thu Jun 27 2024
window.addEventListener("turbo:render", () => {
  const elements = document.querySelectorAll("scroll-indicator")

  for (const element of elements) {
    element.displayIndicators()
  }
})

if (!window.customElements.get("scroll-indicator")) {
  window.ScrollIndicator = ScrollIndicator
  window.customElements.define("scroll-indicator", ScrollIndicator)
}
